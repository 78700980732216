import React from 'react';
import { graphql } from 'gatsby';

import { HTMLContent } from '../components/content';
import Header from '../components/header';
import LocationList from '../components/locationList';

const View = ({ pageContext, data, location }) => {
  const { current: info, related: related } = data;

  return (
    <article className="page view">
      <Header
        title={info.frontmatter.title}
        back="/vistas"
        intro={info.frontmatter.intro}
        path={location.pathname}
      />
      <HTMLContent content={info.html} />

      {related.edges.length ? (
        <>
          <h2>Nesta vista</h2>
          <LocationList locations={related.edges} />
        </>
      ) : null}
    </article>
  );
};

export default View;

export const query = graphql`
  query($slug: String!, $title: String!) {
    current: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        intro
      }
      html
    }

    related: allMarkdownRemark(
      filter: { frontmatter: { view: { eq: $title } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            type
          }
        }
      }
    }
  }
`;
